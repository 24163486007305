import { BevoegdGezagOutput, DeelnemerOutput, DeskundigeOutput, WebGebruikerGroep } from '@/lib/types';
import { MilieudienstOutput } from '@/lib/types/milieudienst';
import { Form, Input, Select, Switch } from 'antd';
import { Store } from 'antd/es/form/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { constants } from '@/lib/constants';

export interface WebGebruikerFormProps {
  onFinish: (values: any) => void | undefined;
  milieudiensten: MilieudienstOutput[];
  deskundigen: DeskundigeOutput[];
  bevoegdGezagen: BevoegdGezagOutput[];
  deelnemers: DeelnemerOutput[];
  initialValues?: Store | undefined;
  id?: string | undefined;
  initialWebGebruikerGroep?: WebGebruikerGroep | undefined;
  isCreate: boolean;
}
export const WebGebruikerForm: React.FunctionComponent<WebGebruikerFormProps> = ({
  onFinish,
  initialWebGebruikerGroep,
  milieudiensten,
  deskundigen,
  bevoegdGezagen,
  deelnemers,
  initialValues,
  id,
  isCreate,
}) => {
  const [webgebruikerGroep, setWebGebruikerGroep] = useState(initialWebGebruikerGroep);
  const { t } = useTranslation('core');
  const [form] = Form.useForm();
  const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 14 } };

  return (
    <Form id={id} className="form oneColumn" {...formItemLayout} layout={'horizontal'} form={form} initialValues={initialValues} onFinish={onFinish}>
      {isCreate && (
        <>
          <Form.Item label={t('webGebruiker.loginNaam')} name="loginNaam" rules={[]}>
            <Input />
          </Form.Item>
          <Form.Item label={t('webGebruiker.email')} name="email" rules={[{ required: true, type: 'email' }]}>
            <Input />
          </Form.Item>
        </>
      )}
      <Form.Item label={t('webGebruiker.contactpersoon')} name="contactpersoon" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('webGebruiker.functieContactpersoon')} name="functieContactpersoon" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('webGebruiker.ondertekenaar')} name="ondertekenaar" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('webGebruiker.functieOndertekenaar')} name="functieOndertekenaar" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('webGebruiker.webGebruikerGroep')} name="webGebruikerGroepId" rules={[{ required: true }]}>
        <Select
          onChange={setWebGebruikerGroep}
          options={Object.values(WebGebruikerGroep)
            .filter(v => !isNaN(Number(v)))
            .map((item, _) => {
              return { value: item, label: WebGebruikerGroep[item] };
            })}
        />
      </Form.Item>

      {(webgebruikerGroep === WebGebruikerGroep.Gemeente || webgebruikerGroep === WebGebruikerGroep.Waterschap) && (
        <Form.Item label={t('webGebruiker.bevoegdGezag')} name="bevoegdgezagId" rules={[{ required: true }]}>
          <Select>
            {(bevoegdGezagen as BevoegdGezagOutput[]).filter(x => x.bevoegdGezagTypeId === (webgebruikerGroep === WebGebruikerGroep.Gemeente ? constants.gemeenteRolId : constants.milieudienstRolId)).map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.volledigeNaam}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {webgebruikerGroep === WebGebruikerGroep.Deelnemer && (
        <Form.Item label={t('webGebruiker.deelnemer')} name="deelnemerId" rules={[{ required: true }]}>
          <Select
            options={deelnemers.map((item, _) => {
              return { value: item.id, label: item.bedrijfsnaam };
            })}
          />
        </Form.Item>
      )}
      {webgebruikerGroep === WebGebruikerGroep.Milieudienst && (
        <Form.Item label={t('webGebruiker.milieudienst')} name="milieudienstId" rules={[{ required: true }]}>
          <Select
            options={milieudiensten.map((item, _) => {
              return { value: item.id, label: item.volledigeNaam };
            })}
          />
        </Form.Item>
      )}
      {webgebruikerGroep === WebGebruikerGroep.Deskundige && (
        <Form.Item label={t('webGebruiker.deskundige')} name="deskundigeId" rules={[{ required: true }]}>
          <Select
            options={deskundigen.map((item, _) => {
              return { value: item.id, label: item.bedrijfsNaam };
            })}
          />
        </Form.Item>
      )}
    </Form>
  );
};